import React, { useState } from 'react';
import '../buildingBlockStyles.css';
import { Checkbox } from '@fluentui/react';
import { ActionButton } from '@fluentui/react';
import RoundIconButton from '../../gooey/components/RoundIconButton';
import { processBuildingBlock } from '../processBuildingBlock';
import { processFormat } from '../processBuildingBlock';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { producturl } from '../../utils/producturl';
import { CommandButton } from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import { styled } from '@fluentui/utilities';
import { isEmpty } from 'lodash';

import * as TextMapping from '../../utils/textMapping';

function TileViewBase({
  pdfDownloadWaiting,
  entities,
  handleSelectedEntitiesChange,
  onEntityInvoke,
  downloadPDF,
  selectedEntities,
  texts,
  track,
  updateFavoriteStatus,
  favoriteList,
  notifyShared,
  messageBar,
  activeId,
  similarId,
  findSimilarMaterials,
  appContent,
  theme,
}) {
  let items = [];
  const [addedStyles, setAddedStyles] = useState([]);
  const [visibleSubItems, setVisibleSubItems] = useState([]);

  const DEFAULT_ICON_COLOR = '#005776';

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += styleElement.replace('.amdc-block', '.amdc-block .tileview') + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function onCheckboxChange(metadata, value) {
    if (value === true) {
      handleSelectedEntitiesChange([...selectedEntities, metadata]);
    } else {
      handleSelectedEntitiesChange(
        selectedEntities.filter((entity) => {
          return entity.id !== metadata.id;
        })
      );
    }
  }

  function toggleSubItemVisibility(id) {
    if (visibleSubItems.includes(id)) {
      setVisibleSubItems(visibleSubItems.filter((item) => item !== id));
    } else {
      setVisibleSubItems([...visibleSubItems, id]);
    }
  }

  function processSubItemToggle(action) {
    let iconName = visibleSubItems.includes(action.id) ? 'ChevronUp' : 'ChevronRight';

    return (
      <ActionButton
        data-testid={`subitem-toggle`}
        iconProps={{
          iconName: iconName,
          styles: {
            root: {
              color: theme?.semanticColors?.tileActionButtonIcon ? theme.semanticColors.tileActionButtonIcon : DEFAULT_ICON_COLOR,
            },
          },
        }}
        styles={{
          label: {
            color: theme?.semanticColors?.tileActionButtonText ? theme.semanticColors.tileActionButtonText : DEFAULT_ICON_COLOR,
          },
        }}
        onClick={() => {
          toggleSubItemVisibility(action.id);
        }}
        title={TextMapping.getUIText(action.tooltip, texts)}
      >
        {action.label ? TextMapping.getUIText(action.label, texts) : ''}
      </ActionButton>
    );
  }

  function processCheckbox(action, format) {
    return (
      <Checkbox
        data-testid={`tile-checkbox`}
        className={format}
        checked={selectedEntities.filter((e) => e.id === action.id).length > 0}
        onChange={(e, value) => {
          onCheckboxChange(action, value);
        }}
      />
    );
  }

  function processIcon(name, action) {
    if (action.click === 'datasheet') {
      if (action.style === 'action') {
        return (
          <ActionButton
            data-testid={`details-button`}
            iconProps={{
              iconName: name,
              styles: {
                root: {
                  color: theme?.semanticColors?.tileActionButtonIcon ? theme.semanticColors.tileActionButtonIcon : DEFAULT_ICON_COLOR,
                },
              },
            }}
            styles={{
              label: {
                color: theme?.semanticColors?.tileActionButtonText ? theme.semanticColors.tileActionButtonText : DEFAULT_ICON_COLOR,
              },
            }}
            onClick={() => {
              onEntityInvoke(action);
              // onActiveEntityChanged(action);
              //handleSelectedEntitiesChange([action]);
            }}
            title={TextMapping.getUIText(action.tooltip, texts)}
          >
            {action.label ? TextMapping.getUIText(action.label, texts) : ''}
          </ActionButton>
        );
      } else {
        return (
          <RoundIconButton
            data-testid={`details-button`}
            iconProps={{
              iconName: name,
              styles: {
                root: {
                  color: action.iconColor ? action.iconColor : DEFAULT_ICON_COLOR,
                },
              },
            }}
            onClick={() => {
              onEntityInvoke(action);
              // onActiveEntityChanged(action);
              //handleSelectedEntitiesChange([action]);
            }}
            title=""
          />
        );
      }
    }
    if (action.click === 'similar') {
      let menuProps = null;
      if (action.options && Array.isArray(action.options) && action.options.length > 1) {
        let options = [];
        for (let option of action.options) {
          options.push({
            key: option.key,
            text: option.name,
            onClick: () => {
              findSimilarMaterials(action, option);
            },
          });
        }
        menuProps = {};
        menuProps.items = options;
      }

      if (action.style === 'action') {
        return (
          <ActionButton
            data-testid={`similar-button`}
            iconProps={{
              iconName: name,
              styles: {
                root: {
                  color: theme?.semanticColors?.tileActionButtonIcon ? theme.semanticColors.tileActionButtonIcon : DEFAULT_ICON_COLOR,
                },
              },
            }}
            styles={{
              label: {
                color: theme?.semanticColors?.tileActionButtonText ? theme.semanticColors.tileActionButtonText : DEFAULT_ICON_COLOR,
              },
            }}
            onClick={() => {
              if (isEmpty(menuProps)) {
                findSimilarMaterials(action);
              }
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                const similarMaterialsMenu = document.querySelector('.ms-ContextualMenu');
                if (similarMaterialsMenu) {
                  setTimeout(() => {
                    if (!similarMaterialsMenu.isMouseInside) {
                      similarMaterialsMenu.style.display = 'none';
                    }
                  }, 500);
                }
              }, 500);
            }}
            onMenuClick={() => {
              setTimeout(() => {
                const similarMaterialsMenu = document.querySelector('.ms-ContextualMenu');
                if (similarMaterialsMenu) {
                  similarMaterialsMenu.addEventListener('mouseover', () => {
                    similarMaterialsMenu.isMouseInside = true;
                  });

                  similarMaterialsMenu.addEventListener('mouseout', () => {
                    similarMaterialsMenu.isMouseInside = false;

                    setTimeout(() => {
                      if (!similarMaterialsMenu.isMouseInside) {
                        similarMaterialsMenu.style.display = 'none';
                      }
                    }, 100);
                  });
                }
              }, 0);
            }}
            menuProps={menuProps}
            title={TextMapping.getUIText(action.tooltip, texts)}
          >
            {action.label ? TextMapping.getUIText(action.label, texts) : ''}
          </ActionButton>
        );
      } else {
        return (
          <RoundIconButton
            data-testid={`similar-button`}
            iconProps={{
              iconName: name,
              styles: {
                root: {
                  color: action.iconColor ? action.iconColor : DEFAULT_ICON_COLOR,
                },
              },
            }}
            onClick={() => {
              findSimilarMaterials(action);
              // onActiveEntityChanged(action);
              //handleSelectedEntitiesChange([action]);
            }}
            title="Similar Materials"
          />
        );
      }
    } else if (action.click === 'share') {
      let items = [];
      if (action.clipboard) {
        items.push({
          key: 'clipboard',
          text: TextMapping.getUIText(TextMapping.UI_TEXT_COPY_TO_CLIPBOARD, texts),
          iconProps: { iconName: 'Copy' },
          onClick: () => {
            let shareURL = producturl(
              action.materialname,
              window.location.protocol + '//' + window.location.host + window.location.pathname,
              track.get(action.id)
            );

            navigator.clipboard.writeText(shareURL);
            notifyShared();
          },
        });
      }

      if (action.email) {
        items.push({
          key: 'email',
          text: TextMapping.getUIText(TextMapping.UI_TEXT_SEND_VIA_EMAIL, texts),
          iconProps: { iconName: 'Mail' },
          onClick: () => {
            let shareURL = producturl(
              action.materialname,
              window.location.protocol + '//' + window.location.host + window.location.pathname,
              track.get(action.id)
            );
            let mailText = TextMapping.getUIText(TextMapping.UI_TEXT_SHARE_TEXT, texts, {
              shareURL: encodeURI(shareURL),
              materialname: encodeURIComponent(action.materialname),
            });

            window.location.href = `mailto:?${mailText}`;
          },
        });
      }
      if (action.style === 'action' && (action.email || action.clipboard)) {
        return (
          <CommandButton
            data-testid={`share-button`}
            iconProps={{
              iconName: name,
              styles: {
                root: {
                  color: theme?.semanticColors?.tileActionButtonIcon ? theme.semanticColors.tileActionButtonIcon : DEFAULT_ICON_COLOR,
                },
              },
            }}
            menuProps={{
              items: items,
            }}
            styles={{
              label: {
                color: theme?.semanticColors?.tileActionButtonIcon ? theme.semanticColors.tileActionButtonIcon : DEFAULT_ICON_COLOR,
              },
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                const shareMaterialsMenu = document.querySelector('.ms-ContextualMenu');
                if (shareMaterialsMenu) {
                  setTimeout(() => {
                    if (!shareMaterialsMenu.isMouseInside) {
                      shareMaterialsMenu.style.display = 'none';
                    }
                  }, 500);
                }
              }, 500);
            }}
            onMenuClick={() => {
              setTimeout(() => {
                const shareMaterialsMenu = document.querySelector('.ms-ContextualMenu');
                if (shareMaterialsMenu) {
                  shareMaterialsMenu.addEventListener('mouseover', () => {
                    shareMaterialsMenu.isMouseInside = true;
                  });

                  shareMaterialsMenu.addEventListener('mouseout', () => {
                    shareMaterialsMenu.isMouseInside = false;

                    setTimeout(() => {
                      if (!shareMaterialsMenu.isMouseInside) {
                        shareMaterialsMenu.style.display = 'none';
                      }
                    }, 100);
                  });
                }
              }, 0);
            }}
            title={TextMapping.getUIText(action.tooltip, texts)}
          >
            {action.label ? TextMapping.getUIText(action.label, texts) : ''}
          </CommandButton>
        );
      } else {
        return (
          <RoundIconButton
            data-testid={`share-button`}
            iconProps={{
              iconName: name,
              styles: {
                root: {
                  color: action.iconColor ? action.iconColor : DEFAULT_ICON_COLOR,
                },
              },
            }}
            onClick={() => {
              //onEntityInvoke(action);
              // onActiveEntityChanged(action);
              //handleSelectedEntitiesChange([action]);
            }}
            title="Share"
          />
        );
      }
    } else if (action.click === 'pdf') {
      if (pdfDownloadWaiting.has(action.id)) {
        return <Spinner data-testid={`loading-spinner`} size={SpinnerSize.small} />;
      } else {
        if (action.style === 'action') {
          return (
            <ActionButton
              data-testid={`pdf-button`}
              iconProps={{
                iconName: name,
                styles: {
                  root: {
                    color: theme?.semanticColors?.tileActionButtonIcon ? theme.semanticColors.tileActionButtonIcon : DEFAULT_ICON_COLOR,
                  },
                },
              }}
              styles={{
                label: {
                  color: theme?.semanticColors?.tileActionButtonText ? theme.semanticColors.tileActionButtonText : DEFAULT_ICON_COLOR,
                },
              }}
              onClick={() => {
                downloadPDF(action, action.materialname);
              }}
              title={TextMapping.getUIText(action.tooltip, texts)}
            >
              {action.label ? TextMapping.getUIText(action.label, texts) : ''}
            </ActionButton>
          );
        } else {
          return (
            <RoundIconButton
              data-testid={`pdf-button`}
              iconProps={{
                iconName: name,
                styles: {
                  root: {
                    color: action.color ? action.color : DEFAULT_ICON_COLOR,
                  },
                },
              }}
              onClick={() => {
                downloadPDF(action, action.materialname);
              }}
              title="PDF"
            />
          );
        }
      }
    } else if (action.click === 'favorite') {
      return (
        <IconButton
          title="Favorite"
          iconProps={{
            iconName: favoriteList.includes(action.id) ? 'FavoriteStarFill' : 'FavoriteStar',
            styles: action.styles,
          }}
          onClick={() => updateFavoriteStatus(action.id)}
        />
      );
    }
  }

  function handleTextAction(content, action, format) {
    if (action.click === 'datasheet') {
      return (
        <button
          key={'datasheetbutton-' + action.id}
          className={processFormat('border-none bg-none cursor-pointer text-left p-0 ' + format)}
          onClick={() => {
            onEntityInvoke(action);
            // onActiveEntityChanged(action);
            //handleSelectedEntitiesChange([action]);
          }}
        >
          {content}
        </button>
      );
    } else if (action.click === 'pdf') {
      return (
        <button
          className={processFormat('border-none bg-none cursor-pointer text-left p-0 ' + format)}
          onClick={() => {
            downloadPDF(action, action.name);
          }}
        >
          {content}
        </button>
      );
    } else if (action.click === 'toggle') {
      return (
        <button
          className={processFormat('border-none bg-none cursor-pointer text-left p-0 ' + format)}
          onClick={() => {
            toggleSubItemVisibility(action.id);
          }}
        >
          {content}
        </button>
      );
    }
  }
  // default class for tiles section; maybe overwritten by format in style bb
  let tilesClass = 'flex flex-wrap gap px-3 flex-1';

  if (entities) {
    for (let entity of entities) {
      let body = [];
			if (entity && entity.data && entity.data.content) {
				for (let buildingBlock of entity.data.content) {
					processBuildingBlock({
						appContent,
						buildingBlock,
						body,
						processCheckbox,
						processIcon,
						handleTextAction,
						addStyle,
						texts,
						processSubItemToggle,
					});
				}

				if (body && body.length > 0) {
					let key = 'tile-' + entity.data?.action?.id;
					let className = '';
					let isChildVisible = entity.data.subitem && visibleSubItems.includes(entity.data.action?.subitemid);
					if (entity.data?.action?.id === activeId) {
						className = 'matselect';
					}
					if (entity.data?.action?.id === similarId) {
						className += ' similar';
					}
					if (entity.data.subitem) {
						className += ' stacked-child';
					}
					if (isChildVisible) {
						className += ' stacked-child-visible';
					}
					if (entity.data.grouping && !entity.data.subitem) {
						className += ' stacked ';
					}

					if (entity.data.grouping && !entity.data.subitem && visibleSubItems.includes(entity.data.grouping)) {
						className += ' stacked-open ';
					}

					// check if bb include tile-* class. if not use default
					let tileClass = entity.data.format && entity.data.format.includes('tile-') ? ' ' : ' tile-small flex flex-col flex-1 ';
					let isVisible = entity.data.subitem ? isChildVisible : true;
					let item = (
						<div
							style={{ display: isVisible ? 'flex' : 'none' }}
							id={key}
							key={key}
							className={`${className} ${tileClass} ${processFormat(entity.data.format)} `}
						>
							{body}
						</div>
					);
					items.push(item);
				}
			} else if (entity && entity.type === 'style') {
				processBuildingBlock({ appContent, buildingBlock: entity, body, addStyle, texts });
				if (entity.data.format && entity.data.format.length) tilesClass = entity.data.format;
			}
    }
  }

  return (
    <div data-testid="building-block-tile" className={'tileview custom-palette ' + tilesClass + ' tileViewBackground'}>
      {messageBar}
      {items}
    </div>
  );
}

const TileView = styled(TileViewBase, () => {}, undefined, { scope: 'TileView' });

export default React.memo(TileView);
